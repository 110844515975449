function getLocaleStorageItem(key) {
  return JSON.parse(localStorage.getItem(key));
}
function setLocaleStorageItem(key, value) {
  return localStorage.setItem(key, value);
}
function removeLoaleStorageItem(key, value) {
  return localStorage.removeItem(key);
}

export { getLocaleStorageItem, setLocaleStorageItem, removeLoaleStorageItem };
