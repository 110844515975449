import React, { Suspense, useState, lazy, useEffect, useContext } from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ThemeContext from "./context/ThemeContext";
import Loader from "./jsx/components/MyLoader/Loader";
import SessionProvider from "./context/SessionContext";
import { ToastContainer } from "react-toastify";
const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <ThemeContext>
        <SessionProvider>
          <Suspense fallback={<Loader height={"95vh"} />}>
            <ToastContainer placement="top-right" />
            <App />
          </Suspense>
        </SessionProvider>
      </ThemeContext>
    </BrowserRouter>
  </Provider>
);
reportWebVitals();
