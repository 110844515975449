import axios from "axios";
import swal from "sweetalert";
import {
  adminLoginConfirmAction,
  adminLogout,
  loginConfirmedAction,
  Logout,
} from "../store/actions/AuthActions";
import { commonUrl, commonUrlAdmin } from "../jsx/components/CommonUrl";
import { removeLoaleStorageItem } from "../globle/utils/localeStorage";

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(orgCode, mobileNo, password) {
  const postData = {
    orgCode,
    mobileNo,
    password,
  };
  return axios.post(`${commonUrl}/user/login`, postData);
}
export function adminLogin(mobileNo, password) {
  const postData = {
    mobileNo,
    password,
  };
  return axios.post(`${commonUrlAdmin}admin/user/login`, postData);
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      break;
    case "Email not found!!":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "Incorrect email and password combination !!":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("userDetails");

  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);

  const data = {
    mobileNo: tokenDetails.userData?.mobileNo,
    idToken: tokenDetails.userData?.authToken,
    password: 553292,
    orgCode: tokenDetails.userData?.schoolCode,
    permissions: tokenDetails?.permission,
  };
  dispatch(loginConfirmedAction(data));
}
export function checkAdminAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("adminDetails");

  if (!tokenDetailsString) {
    dispatch(adminLogout(navigate));
    return;
  }
  const data = {
    mobileNo: JSON.parse(tokenDetailsString).userData.mobileNo,
    accessToken: JSON.parse(tokenDetailsString).accessToken,
  };
  removeLoaleStorageItem("userDetails");
  dispatch(adminLoginConfirmAction(data));
}

export function isLogin() {
  const tokenDetailsString = localStorage.getItem("userDetails");

  if (tokenDetailsString) {
    return true;
  } else {
    return false;
  }
}
