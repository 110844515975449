import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { commonUrl } from "../jsx/components/CommonUrl";

export const SessionContext = createContext()


function SessionProvider({ children }) {
    const [sessionList, setSessionList] = useState([])

    const [sessionData, setSessionData] = useState(JSON.parse(localStorage.getItem("activeSession")) || { isDefault: 1, id: 1 })
    const [currentSession, setCurrentSession] = useState("")
    const sessionId = sessionData.id

    useEffect(()=>{
        localStorage.setItem("activeSession", JSON.stringify(sessionData))
    },[sessionData])

    return (
        <SessionContext.Provider value={{ sessionId, sessionList, setSessionList, currentSession, setCurrentSession, setSessionData, }}>
            {children}
        </SessionContext.Provider>
    )


}
export default SessionProvider