import React from 'react'
import { DotLoader, PropagateLoader } from 'react-spinners'

function Loader({ height }) {
    return (
        <>
            <div id='myLoader' style={{ minHeight: height ? height : '70vh', display: 'flex', alignItems: "center", justifyContent: "center" }} className='d-flex align-items-center justify-content-center'>
                <PropagateLoader color="#036666" />
            </div>
        </>
    )
}

export default Loader