import {
    LOGIN_CONFIRMED_ACTION,
    LOGOUT_ACTION,

} from '../actions/AuthActions';

const initialState = {
    auth: {
        password: '',
        mobileNo: '',
    },
 
};

export function AdminAuthReducer(state = initialState, action) {
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
    }

  
    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                email: '',
                idToken: '',
                localId: '',
                expiresIn: '',
                refreshToken: '',
            },
         
        };
    }

   
    return state;
}